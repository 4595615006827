<template>
    <v-container class="page-container" fill-height fluid grid-list-xl
    >
        <v-layout class="self-care-layout" wrap id="configuration">
            <!-- Header title -->
            <h1 class="page-title text-xs-left mb-3 w-100">{{pageTitle}}</h1>

            <!-- Add skill button -->
            <div class="flex-display flex-row flex-justify-end mb-3 w-100">
                <button class="btn add-new mx-2" @click="manageRequirements()">
                    Manage Requirements
                </button>

                <button class="btn add-new mx-2" @click="showAddSkillCategoryDialog()">
                    <i class="v-icon mdi mdi-plus"></i> New Category
                </button>

                <button class="btn add-new mx-2" @click="showAddSkillDialog('add',null)">
                    <i class="v-icon mdi mdi-plus"></i> {{addButtonText}}
                </button>
            </div>

            <!-- Skills list -->
            <template v-if="isReady">
                <div class="item-container"
                     v-for="(skill, index) in skills"
                     :key="skill.id"
                >
                    <p class="item-text w-100">{{skill.name}}</p>
                    <p class="item-text w-100"><i aria-hidden="true"
                                                  class="rating-number-icon v-icon mdi mdi-account theme--light tertiary--text"></i>{{skill.total_times_rating}}
                    </p>
                    <p v-if="skill.average_rating" class="item-text w-100">Average : {{skill.average_rating}}</p>
                    <p v-else class="item-text w-100">Average : 0</p>
                    <div class="item-actions">
                        <button :disabled="therapists.length <= 0" class="btn-edit" @click="showAddSkillDialog('edit', index)">Edit</button>
                        <button class="btn-delete ml-2" @click="showDeleteSkillDialog(skill.id)">Delete</button>
                    </div>
                </div>
            </template>

            <!-- Add skill dialog -->
            <add-skill v-if="addSkillDialog"
                       :visible="addSkillDialog"
                       :actionType="actionType"
                       :skills="skills"
                       :therapists="therapists"
                       :therapies="therapies"
                       :categories="categories"
                       :requirements="requirements"
                       :value="selectedSkill"
                       v-on:submitSkill="submitSkill"
                       v-on:hide="hideAddSkillDialog"
            >
            </add-skill>

            <!--Add skill category dialog-->
            <add-skill-category v-if="addSkillCategoryDialog"
                                :visible="addSkillCategoryDialog"
                                v-on:submitSkillCategory="submitSkillCategory"
                                v-on:hide="hideAddSkillCategoryDialog">

            </add-skill-category>

            <!-- delete dialog -->
            <confirmation v-if="deleteSkillDialog" :visible="deleteSkillDialog">
                <template slot="title">
                    Are you sure?
                </template>
                <template slot="desc">
                    This Skill will be deleted. This action cannot be undone.
                </template>
                <template slot="actions">
                    <a class="cancel-model" @click="hideConfirmation">Cancel</a>
                    <button class="btn modal-action-button confirmation-delete" @click="deleteSkill">Delete</button>
                </template>
            </confirmation>
        </v-layout>

    </v-container>
</template>

<script>
    import AddSkill from "../components/configuration/AddSkill";
    import Confirmation from "../components/common/Confirmation";
    import AddSkillCategory from "../components/configuration/AddSkillCategory";
    export default {
        name: 'SelfCareSkills',
        components: {
            AddSkill,
            Confirmation,
            AddSkillCategory,
        },
        data: () => ({
            pageTitle: 'Self Care Skills',
            addButtonText: 'New Skill',
            isReady: true,
            addSkillDialog: false,
            addSkillCategoryDialog: false,
            deleteSkillDialog: false,
            actionType: '',
            skills: [],
            therapists: [],
            therapies: [],
            categories: [],
            requirements: [],
            selectedIndex: null,
            selectedSkill: null,
            activeSkill: 0,
        }),
        created() {
            this.resetSelectedSkill();
            this.getSelfCareSkills();
            this.getRequiredData();
        },
        methods: {
            showDeleteSkillDialog(id) {
                this.deleteSkillDialog = true;
                this.activeSkill =  id;
            },
            hideConfirmation() {
                this.deleteSkillDialog = false;
                this.activeSkill = 0;
            },
            deleteSkill() {
                this.deleteSkillDialog = false;
                let loader = this.$loading.show();
                this.$http.delete(this.url+'self_care/skill_delete', {
                    data: {
                        id: this.activeSkill
                    }
                }).then((response) => {
                    loader.hide();
                    this.$toastr('success', response.data.message);
                    this.getSelfCareSkills();
                }).catch((error) => {
                    loader.hide();
                    this.$toastr('error',error.response.data.message);
                })
            },
            getSelfCareSkills() {
                let loader = this.$loading.show();
                this.$http.get(this.url + 'self_care/skills').then(response => {
                    this.skills = response.data.skills;
                    loader.hide();
                }).catch(error => {
                    this.$toastr('error', error.response.data.message, 'Error');
                    loader.hide();
                })
            },
            submitSkillCategory(category) {
                let loader = this.$loading.show();
                this.$http.put(this.url + 'self_care/skill_category', {
                    name: category
                }).then(response => {
                    this.categories.push(response.data.category);
                    this.hideAddSkillCategoryDialog();
                    this.$toastr('success', response.data.message, '');
                    console.log("Inside success");
                    loader.hide();
                }).catch(error => {
                    console.log("Inside error: " + error);
                    this.$toastr('error', error.response.data.message, '');
                    loader.hide();
                });
            },
            submitSkill(skill) {
                // parse multiselect data
                let relatedSkills = skill.related_skills && skill.related_skills.length > 0 ?
                    this.getSelectedIds(skill.related_skills) :
                    '';

                let requirements = skill.requirements && skill.requirements.length > 0 ?
                    this.getSelectedIds(skill.requirements) :
                    '';

                let loader = this.$loading.show();
                this.$http.put(this.url + 'self_care/skill', {
                    ...skill,
                    related_skills: relatedSkills,
                    requirements: requirements
                }).then(response => {
                    this.selectedIndex === null ?
                        this.skills.push(response.data.skill) :
                        this.skills[this.selectedIndex] = response.data.skill;
                    this.hideAddSkillDialog();
                    this.$toastr('success', response.data.message, '');
                    loader.hide();
                }).catch(error => {
                    alert(error.response.data.message)
                    this.$toastr('error', error.response.data.message, '');
                    loader.hide();
                });
            },
            showAddSkillDialog(action, index) {
                this.actionType = action;
                this.selectedIndex = index;
                if (this.selectedIndex === null) {
                    this.resetSelectedSkill();
                } else {
                    this.selectedSkill = this.skills[index];
                }
                this.addSkillDialog = true;
            },
            hideAddSkillDialog() {
                this.addSkillDialog = false;
                this.resetSelectedSkill();
            },
            showAddSkillCategoryDialog() {
                this.addSkillCategoryDialog = true;
            },
            hideAddSkillCategoryDialog() {
                this.addSkillCategoryDialog = false;
            },
            resetSelectedSkill() {
                this.selectedIndex = null;
                this.selectedSkill = {
                    user_id: null,
                    name: '',
                    therapy_code: '',
                    description: '',
                    duration_count: '',
                    duration_unit: '',
                    orientation: '',
                    url: '',
                    thumbnail_url: '',
                    web_url: '',
                    web_thumbnail_url: '',
                    animation: '',
                    status: '',
                    related_skills: [],
                    requirements: [],
                    is_locked: 1,
                    is_introductory: 0,
                    category_id: 0,
                    end_description : '',
                    keywords: []
                }
            },
            getSelectedIds(data) {
                let _ids = [];
                for (let i = 0; i < data.length; i++) {
                    _ids.push(data[i].id);
                }
                return _ids.toString();
            },
            getRequiredData() {
                this.therapists = [];
                this.$http.get(this.url + 'self_care/skills/supporting_data').then(response => {
                    this.therapies = response.data.therapies;
                    this.therapists = response.data.therapists;
                    this.categories = response.data.categories;
                    this.requirements = response.data.requirements;
                }).catch(error => {
                })
            },
            manageRequirements() {
                window.location = 'selfCareSkills/requirements';
            }
        }
    }
</script>
<style scoped>
    .rating-number-icon {
        margin: 0 0 -4px 5px;
    }
</style>
